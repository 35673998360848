






























































































// Vue
import { Vue, Component } from 'vue-property-decorator'
// Table
import { AgGridVue } from 'ag-grid-vue'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
// Mixin
import { mobileWidthMapper } from '@/store/modules/mobileWidth'
import AgGridFactory from '@/factories/agGridFactory'
import { FormOptions, FormSchema } from 'vue-form-generator'
import { userMapper } from '@/store/modules/user'
// UI
import ClassicCKEditor from '@/components/ui/ClassicCKEditor.vue'
import { PermissionManager } from '@/api/permissions/PermissionManager'
import { toastMapper } from '@/store/modules/toast'
import validators from '@/utils/validators'
import MainCard from '@/components/ui/MainCard.vue'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['currentUser']),
    ...mobileWidthMapper.mapState(['isMobile'])
  },
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: {
    AgGridVue,
    ActionRenderer,
    ClassicCKEditor,
    MainCard
  }
})
export default class DevelopmentPage extends Mapper {
  // ================ Start Table ================
  private columnDefs = [
    {
      headerName: 'Фамилия',
      field: 'surname',
      colId: 'surname',
      filterParams: {
        buttons: ['reset'],
        suppressAndOrCondition: true
      }
    },
    {
      headerName: 'Имя',
      field: 'name',
      colId: 'name',
      filterParams: {
        buttons: ['reset'],
        suppressAndOrCondition: true
      }
    },
    {
      headerName: 'Отчество',
      field: 'midname',
      colId: 'midname',
      filterParams: {
        buttons: ['reset'],
        suppressAndOrCondition: true
      }
    },
    {
      ...AgGridFactory.getActionColumn({
        cellRendererParams: {
          onEdit: (e: any) => {
            console.debug(e)
          },
          onDelete: (e: any) => {
            console.debug(e)
          },
          onInfo: (e: any) => {
            console.debug(e)
          }
        },
        width: 140,
        maxWidth: 140
      })
    }
  ]
  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    pagination: true,
    paginationPageSize: 15
  }
  private rowData = [
    {
      surname: 'Иванов',
      name: 'Владимир',
      midname: 'Сергеевич'
    },
    {
      surname: 'Ковынев',
      name: 'Максим',
      midname: 'Владимирович'
    }
  ]
  // ================ End Table ================

  private model = {
    greeting: ['hello'],
    greetingRadio: 'hello',
    texteditors: 'notepad++',
    text: 'Hello world!',
    year: 2020,
    rowData: this.rowData,
    date: '',
    skills: '',
    multiselectFIO: '',
    message: '<strong>Text</strong>',
    phone: null,
    datetime: null
  }

  private ckeditorSampleText = '<p><b>Hello</b></p>'

  private userRules = PermissionManager.pages().rules

  private schema: FormSchema = {
    fields: [
      {
        type: 'bootstrapRadioGroup',
        model: 'greetingRadio',
        label: 'Language Greeting',
        styleClasses: 'wm-100',
        values: ['hello', 'hola', 'привет']
      },
      {
        type: 'multiselect',
        inputType: 'text',
        model: 'texteditors',
        label: 'Editor',
        options: ['vim', 'emacs', 'notepad++'],
        styleClasses: 'wm-50 pr-2',
        tagPlaceholder: ''
      },
      {
        type: 'bootstrap',
        inputType: 'text',
        model: 'text',
        styleClasses: 'wm-50',
        label: 'Sample label',
        placeholder: 'Sample placeholder'
      },
      {
        type: 'bootstrap',
        inputType: 'number',
        label: 'Year',
        model: 'year',
        required: true,
        min: 2000,
        max: 2100,
        number: true
      },
      {
        type: 'agGrid',
        model: 'rowData',
        wrapHeader: true,
        noAddButton: false,
        buttonToolbar: true,
        required: true,
        disabled: false,
        noCloneButton: true,
        columnDefs: [
          {
            headerName: 'Фамилия',
            field: 'surname',
            width: 150
          },
          {
            headerName: 'Имя',
            field: 'name',
            width: 150
          },
          {
            headerName: 'Отчество',
            field: 'midname',
            width: 150
          }
        ],
        gridOptions: {
          ...AgGridFactory.getDefaultGridOptions(),
          stopEditingWhenGridLosesFocus: true,
          domLayout: 'autoHeight',
          defaultColDef: {
            cellClass: 'cell-wrap-text',
            autoHeight: true
          }
        }
      },
      {
        type: 'bootstrapTextArea',
        label: 'TextArea',
        model: 'text',
        required: true,
        hint: 'Sample hint'
      },
      {
        type: 'datepicker',
        label: 'Date',
        model: 'date',
        styleClasses: 'wm-33 pr-2',
        validator: validators.required,
        required: true
      },
      {
        type: 'bootstrapCheckboxGroup',
        model: 'greeting',
        label: 'Language Greeting',
        styleClasses: 'wm-66',
        values: ['hello', 'hola', 'привет'],
        disabled: false
      },
      {
        type: 'bootstrapSelect',
        label: 'Skills',
        model: 'skills',
        options: ['Javascript', 'VueJS', 'CSS3', 'HTML5']
      },
      {
        type: 'CKEditor',
        label: 'Сообщение',
        model: 'message'
      },
      {
        type: 'multiselect',
        inputType: 'text',
        model: 'multiselectFIO',
        label: 'User FIO',
        options: this.rowData,
        styleClasses: 'w-100',
        customLabel({ surname, name, midname }: any) {
          if (surname) {
            return `${surname} ${name} ${midname} - человек`
          }
          return 'Выберите значение'
        }
      },
      {
        type: 'bootstrapMasked',
        inputType: 'text',
        label: 'Телефон',
        model: 'phone',
        mask: '+9(999)999-99-99',
        styleClasses: 'wm-100 pr-2',
        required: true
      },
      {
        type: 'bootstrapDatepicker',
        label: 'Дата',
        model: 'date',
        styleClasses: 'wm-50 pr-2',
        validator: validators.required,
        required: true
      },
      {
        type: 'datetimePicker',
        label: 'Дата и время',
        model: 'datetime',
        styleClasses: 'wm-50',
        validator: validators.required,
        required: true
      }
    ]
  }

  private pushDefaultToast() {
    this.pushToast({
      title: 'Примерный текст заголовка',
      message: 'Тут произвольный <b>HTML</b> текст'
    })
  }

  private pushErrorToast() {
    this.pushToast({
      error: true,
      title: 'Примерный текст заголовка',
      message: 'Тут произвольный <b>HTML</b> текст с ошибкой'
    })
  }
  private pushFastToast() {
    this.pushToast({
      title: 'Примерный текст заголовка',
      message: 'Тут произвольный <b>HTML</b> текст',
      time: 1
    })
  }

  private options: FormOptions = {
    validateAfterLoad: true,
    validateAfterChanged: true
  }
}
